import { loginInitialValues, loginValidationSchema } from "./authValidation";
import {
  changePasswordInitialValues,
  changePasswordValidationSchema,
} from "./changePassoword";
import {
  packageInitialValues,
  packageValidationSchema,
} from "./packageValidation";

const INITIALVALUES = {
  loginInitialValues: loginInitialValues,
  packageInitialValues: packageInitialValues,
  changePasswordInitialValues: changePasswordInitialValues,
};

const VALIDATIONS = {
  loginValidationSchema: loginValidationSchema,
  packageValidationSchema: packageValidationSchema,
  changePasswordValidationSchema: changePasswordValidationSchema,
};

export { VALIDATIONS, INITIALVALUES };
