const QUERY_KEYS = {
  ADMIN_LOGIN_QUERY_KEY: "ADMIN_LOGIN_QUERY_KEY",

  GET_USER_LIST_QUERY_KEY: "GET_USER_LIST_QUERY_KEY",
  GET_USER_DETAIL_QUERY_KEY: "GET_USER_DETAIL_QUERY_KEY",
  UPDATE_USER_STATUS_QUERY_KEY: "UPDATE_USER_STATUS_QUERY_KEY",
  DELETE_USER_QUERY_KEY: "DELETE_USER_QUERY_KEY",

  GET_PROFILE_REQUEST_LIST_QUERY_KEY: "GET_PROFILE_REQUEST_LIST_QUERY_KEY",
  GET_REQUEST_DETAIL_LIST_QUERY_KEY: "GET_REQUEST_DETAIL_LIST_QUERY_KEY",
  GET_VERIFY_COMPANY_LIST_QUERY_KEY: "GET_VERIFY_COMPANY_LIST_QUERY_KEY",

  UPDATE_PROFILE_STATUS_QUERY_KEY: "UPDATE_PROFILE_STATUS_QUERY_KEY",

  UPDATE_COMPANY_STATUS_QUERY_KEY: "UPDATE_COMPANY_STATUS_QUERY_KEY",
  GET_COMPANY_DETAIL_LIST_QUERY_KEY: "GET_COMPANY_DETAIL_LIST_QUERY_KEY",
  DELETE_COMPANY_QUERY_KEY: "DELETE_COMPANY_QUERY_KEY",

  CREATE_PACKAGE_STATUS_QUERY_KEY: "CREATE_PACKAGE_STATUS_QUERY_KEY",
  GET_PACKAGE_LIST_QUERY_KEY: "GET_PACKAGE_LIST_QUERY_KEY",
  UPDATE_PACKAGE_STATUS_QUERY_KEY: "UPDATE_PACKAGE_STATUS_QUERY_KEY",

  CHANGE_PASSWORD_QUERY_KEY: "CHANGE_PASSWORD_QUERY_KEY",
};

export default QUERY_KEYS;
