import React from "react";
import { toast } from "react-custom-alert";
import Swal from "sweetalert2";
import CustomAlert from "../../../common/alert";
import usePost from "../../../../react-query/hooks/usePost";
import ENDPOINTS from "../../../../react-query/services/endPoints";
import QUERY_KEYS from "../../../../react-query/services/queryKeys";
import SideBarWrapper from "../../../common/sidebarWrapper";
import { useLocation, useNavigate } from "react-router-dom";
import useGet from "../../../../react-query/hooks/useGet";
import { IRequestProps } from "../../../../utils/interface";
import RoutePath from "../../../../routes/rouePath";
import { useActiveTab } from "../../../../context/activeTabContext";
import IMAGES from "../../../../utils/constants/images";
import DoumentlistTable from "../../../common/doumentlistTable";
import DetailInfo from "../../../common/detailInfo";
import { PuffLoader } from "react-spinners";

const RequestDetail = () => {
  const location = useLocation();
  let requestedId = location?.state;
  const nav = useNavigate();

  const { setActiveTab } = useActiveTab();

  const { mutateAsync: requestActionMutateAsync } = usePost(
    ENDPOINTS.UPDATE_PROFILE_ACTIONS,
    QUERY_KEYS.UPDATE_PROFILE_STATUS_QUERY_KEY
  );
  const { data, isFetching } = useGet(
    ENDPOINTS.GET_REQUEST_DETAIL,
    QUERY_KEYS.GET_REQUEST_DETAIL_LIST_QUERY_KEY,
    {
      requestId: requestedId,
    }
  );
  let getRequestListdata: IRequestProps = data?.request;

  // Handle accept request action with confirmation
  async function handleRequestAccept(id: string) {
    CustomAlert({
      title: " Accept the request?",
      confirmButtonText: "Accept",
      icon: "question",
      onConfirm: async () => {
        try {
          requestActionMutateAsync({
            requestId: id,
            status: "verified",
          }).then((res) => {
            toast.success(res?.message);
            nav(RoutePath.COMPANYMANAGEMENT.path);
            setActiveTab(1);
          });
        } catch (error: any) {
          Swal.fire({
            title: "Error",
            text: error?.response?.data?.message,
            icon: "error",
            timer: 3000,
            showConfirmButton: false,
          });
        }
      },
    });
  }

  // Handle reject request action with confirmation
  async function handleRequestReject(id: string) {
    CustomAlert({
      title: " Reject the request?",
      confirmButtonText: "Reject",
      icon: "question",
      onConfirm: async () => {
        try {
          requestActionMutateAsync({
            requestId: id,
            status: "rejected",
          }).then((res) => {
            toast.success(res?.message);
            nav(RoutePath.COMPANYMANAGEMENT.path);
            setActiveTab(0);
          });
        } catch (error: any) {
          Swal.fire({
            title: "Error",
            text: error?.response?.data?.message,
            icon: "error",
            timer: 3000,
            showConfirmButton: false,
          });
        }
      },
    });
  }
  return (
    <SideBarWrapper
      title="Request Detail"
      description="Show the detail of the request in this section!"
    >
      <div className="container">
        {isFetching ? (
          <div className="d-flex hv-70 align-items-center justify-content-center">
            <PuffLoader color="#0ad5e7" size={70} />
          </div>
        ) : (
          <div className="row mt-26 g-4">
            <div className="d-flex justify-content-end gap-2">
              <button
                onClick={() => handleRequestAccept(getRequestListdata?._id)}
                className="btn btn-success"
              >
                Accept
              </button>
              <button
                onClick={() => handleRequestReject(getRequestListdata?._id)}
                className="btn btn-danger"
              >
                Reject
              </button>
            </div>
            <div className="col-12 shadow rounded-4">
              <h2 className="info-text mt-3 mb-2">Request Info :</h2>
              <div className="user_detail_wrapper p-3 d-flex justify-content-start gap-4 flex-wrap">
                <div className="user_img_blk">
                  {!getRequestListdata?.profileImage ? (
                    <img src={IMAGES.profileLogo} alt="" />
                  ) : (
                    <img src={getRequestListdata?.profileImage} alt="" />
                  )}
                </div>
                <div className="user_detail">
                  <div className="row row-cols-1 row-cols-md-1 row-cols-xl-3 g-3">
                    <DetailInfo
                      title="Company Name:"
                      info={getRequestListdata?.name}
                    />
                    <DetailInfo
                      title="Registered Address:"
                      info={getRequestListdata?.profile_address}
                    />
                    <DetailInfo
                      title="Email Address:"
                      info={getRequestListdata?.email}
                    />
                    <DetailInfo
                      title="Phone No (Official):"
                      info={`${getRequestListdata?.countryCode} ${getRequestListdata?.phoneNumber}`}
                    />
                    <DetailInfo
                      title="Phone No (Personal):"
                      info={`${getRequestListdata?.countryCode} ${getRequestListdata?.phoneNumber}`}
                    />
                    <DetailInfo
                      title="Webiste link:"
                      link={getRequestListdata?.website}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 shadow rounded-4">
              <h2 className="info-text mt-4 mb-2">Documents :</h2>
              <div className="col-12 px-3 py-3">
                <DoumentlistTable data={getRequestListdata?.documents} />
              </div>
            </div>
          </div>
        )}
      </div>
    </SideBarWrapper>
  );
};

export default RequestDetail;
