import React from "react";
import IMAGES from "../../utils/constants/images";
import { useSideBarContext } from "../../context/sidebarContext";
import { useNavigate } from "react-router-dom";
import RouteStack from "../../routes/routeStack";
import RoutePath from "../../routes/rouePath";

interface IProps {
  children: React.ReactNode;
  title: string;
  description?: string;
}

const SideBarWrapper = ({ children, title, description }: IProps) => {
  const { topBar, toggleSidebar } = useSideBarContext();
  const nav = useNavigate();
  return (
    <div
      className={
        topBar || window.innerWidth <= 992
          ? "main-wrapper main-width"
          : "main-wrapper"
      }
    >
      <div className="content-wrapper">
        <div
          className={
            topBar && window.innerWidth <= 992
              ? "header-blk py-3"
              : "header-blk py-3 d-none"
          }
        >
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="mobile-header">
                  <button
                    className="btn-box"
                    id="optBtn"
                    onClick={toggleSidebar}
                  >
                    <span className="btn_bar btn_width top" />
                    <span className="btn_bar" />
                    <span className="btn_bar btn_width bottom" />
                  </button>
                  <span className="company-name">
                    <img width={45} src={IMAGES.mainLogo} alt="logo" />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="common-section spacer">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="top-heading-blk">
                  <div className="heading">
                    <h1>{title}</h1>
                    <p>{description}</p>
                  </div>
                  <div className="user-info-blk">
                    <span
                      onClick={() => nav(RoutePath.PROFILE.path)}
                      className="userimg"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill-rule="evenodd"
                        stroke-linejoin="round"
                        stroke-miterlimit="2"
                        clip-rule="evenodd"
                        viewBox="0 0 128 128"
                        id="profile"
                      >
                        <path fill="none" d="M0 0h128v128H0z"></path>
                        <clipPath id="a">
                          <path d="M0 0h128v128H0z"></path>
                        </clipPath>
                        <g clip-path="url(#a)">
                          <path
                            fill="#d6f5fc"
                            d="M14.836 99.704c3.45 4.772 5.607 11.802 10.6 16.06 10.393 8.87 26.434 12.608 43.897 8.78 19.374-4.244 35.615-16.738 43.667-31.77 2.692-5.026 3.049-10.517 5.143-15.714 2.16-5.37 6.148-10.787 7.828-16.498 1.683-5.734 2.378-11.622 1.917-17.483-2.31-29.39-32.802-46.976-68.05-39.251C24.596 11.552-2.145 41.686.166 71.076c.46 5.858 2.04 11.248 4.555 16.064 2.505 4.792 6.977 8.225 10.116 12.564Z"
                          ></path>
                          <g
                            fill="#4cbdf2"
                            transform="matrix(34.8596 0 0 19.9198 -29103.7 -12185.3)"
                          >
                            <circle
                              cx="763.733"
                              cy="452.578"
                              r="1"
                              transform="matrix(.46875 0 0 .82031 478.719 243.197)"
                            ></circle>
                            <path d="M836.718 611.722c-1.013 0-1.836 1.439-1.836 3.213 0 1.772.823 3.212 1.836 3.212 1.014 0 1.836-1.44 1.836-3.212 0-1.774-.822-3.213-1.836-3.213Zm.84 4.739c.23-.386.371-.928.371-1.526 0-1.17-.542-2.12-1.21-2.12-.669 0-1.212.95-1.212 2.12 0 .598.143 1.14.372 1.526.153-.54.472-.911.84-.911.367 0 .686.371.84.91Z"></path>
                          </g>
                        </g>
                      </svg>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            {children}
          </div>
        </section>
      </div>
    </div>
  );
};

export default SideBarWrapper;
