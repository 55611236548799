import * as Yup from "yup";

const changePasswordInitialValues = {
  currentPassword: "",
  newPassword: "",
  confirmPassword: "",
};

const changePasswordValidationSchema = Yup.object({
  currentPassword: Yup.string().required("current Password is required*"),
  newPassword: Yup.string()
    .min(5, "New password must be at least 5 characters")
    .required("New password is required*"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("newPassword")], "Passwords must match")
    .required("Confirm password is required"),
});

export { changePasswordInitialValues, changePasswordValidationSchema };
